.image {

	width: 100%;
	height: auto;
	display: block;
	float: none;
	text-align: center;

	svg {
		width: 100%;
		height: auto;
		max-width: 250px;
	}

}

.blueBox {

	position: relative;
	width: 100%;
	border-radius: 5px;
	background-color: var(--color-light-turquoise);
	padding: 20px;
	margin-bottom: 15px;

	p {
		margin: 0;
	}

}
